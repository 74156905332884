import React from "react";
import "./../styles/featuresection.css"; // Ensure to add the CSS in your project
import { Carousel } from "react-bootstrap"; // Import Carousel from Bootstrap
import student1 from "./../img/student1.png";
import student2 from "./../img/student2.png";
import student3 from "./../img/student3.png";

const FeatureSection = () => {
  return (
    <div className="feature-section">
      <h2 className="feature-heading">Why Choose Perfect SOP?</h2>
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="feature-item">
            <h3>AI-Powered Precision</h3>
            <p>
              Generate personalized SOPs with advanced AI technology, tailored
              to your academic and professional background.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="feature-item">
            <h3>Time-Efficient Process</h3>
            <p>
              Complete your SOP in minutes with a guided step-by-step process,
              saving you time and effort.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="feature-item">
            <h3>Expert-Reviewed Content</h3>
            <p>
              Benefit from content that's reviewed and optimized by experts to
              ensure the highest quality.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="feature-item">
            <h3>Secure and Confidential</h3>
            <p>
              Your data is protected with top-notch security measures, ensuring
              confidentiality and peace of mind.
            </p>
          </div>
        </div>
      </div>
      <h2 className="testimonial-heading">What Our Users Say</h2>
      <Carousel
        indicators={false}
        controls={false}
        interval={2000}
        className="testimonial-carousel"
      >
        <Carousel.Item>
          <div className="text-center">
            <img
              src={student1}
              alt="Student 1"
              className="testimonial-image mb-3"
            />
          </div>
          <blockquote className="blockquote text-center">
            <p className="mb-4 testimonial-text">
              "This SOP generator saved me so much time! The process was smooth
              and intuitive."
            </p>
            <footer className="blockquote-footer">
              <span className="student-name">Lakshmi Priya</span>
              <span className="university-name">University of Florida</span>
            </footer>
          </blockquote>
        </Carousel.Item>
        <Carousel.Item>
          <div className="text-center">
            <img
              src={student2}
              alt="Student 2"
              className="testimonial-image mb-3"
            />
          </div>
          <blockquote className="blockquote text-center">
            <p className="mb-4 testimonial-text">
              "I loved the step-by-step guidance. Highly recommend it to
              anyone!"
            </p>
            <footer className="blockquote-footer">
              <span className="student-name">Sahith Rao</span>
              <span className="university-name">Cal Tech</span>
            </footer>
          </blockquote>
        </Carousel.Item>
        <Carousel.Item>
          <div className="text-center">
            <img
              src={student3}
              alt="Student 3"
              className="testimonial-image mb-3"
            />
          </div>
          <blockquote className="blockquote text-center">
            <p className="mb-4 testimonial-text">
              "The best SOP tool I have used. It made my application process
              much easier."
            </p>
            <footer className="blockquote-footer">
              <span className="student-name">Vinay Reddy</span>
              <span className="university-name">
                University of North Texas
              </span>
            </footer>
          </blockquote>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default FeatureSection;
