// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute; /* Adjust to fit within the container */
    top: 0;
    left: 0;
    z-index: 10; /* Ensure it stays above the content within the box */
}

.progress-bar {
    width: 80%;
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.progress {
    height: 100%;
    background-color: #007bff;
    transition: width 0.5s ease;
}

.progress-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/styles/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,kBAAkB,EAAE,uCAAuC;IAC3D,MAAM;IACN,OAAO;IACP,WAAW,EAAE,qDAAqD;AACtE;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".loader-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.8);\n    position: absolute; /* Adjust to fit within the container */\n    top: 0;\n    left: 0;\n    z-index: 10; /* Ensure it stays above the content within the box */\n}\n\n.progress-bar {\n    width: 80%;\n    height: 20px;\n    background-color: #f3f3f3;\n    border-radius: 10px;\n    overflow: hidden;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    margin-bottom: 10px;\n}\n\n.progress {\n    height: 100%;\n    background-color: #007bff;\n    transition: width 0.5s ease;\n}\n\n.progress-text {\n    font-size: 16px;\n    font-weight: bold;\n    color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
