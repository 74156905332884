import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './../styles/feedback.css';

const FeedbackModal = ({ show, onSubmitFeedback }) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [feedback, setFeedback] = useState('');

    const handleSubmit = () => {
        if (rating > 0) {
            onSubmitFeedback({ rating, feedback });
        }
    };

    return (
        <Modal show={show} backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <Modal.Title style={{ textAlign: 'center', width: '100%' }}>
                    We Value Your Feedback!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column align-items-center mb-3">
                    <div className="star-rating mb-3">
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={index <= (hover || rating) ? "star selected" : "star"}
                                    onClick={() => setRating(index)}
                                    onMouseEnter={() => setHover(index)}
                                    onMouseLeave={() => setHover(rating)}
                                >
                                    &#9733;
                                </button>
                            );
                        })}
                    </div>
                    <div className="form-group w-100">
                        <label htmlFor="feedbackText" style={{ fontWeight: 'bold', fontSize: '16px' }}>Your Feedback (Optional)</label>
                        <textarea
                            className="form-control"
                            id="feedbackText"
                            rows="4"
                            placeholder="Write your feedback here..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            style={{ borderRadius: '5px' }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit} style={{ width: '100%' }}>
                    Submit Feedback
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FeedbackModal;
