import React, { useState, useEffect, useRef } from "react";
import { Stepper } from "react-form-stepper";
import UniversityForm from "./UniversityForm";
import AcademicsForm from "./AcademicsForm";
import WorkExperienceForm from "./WorkExperienceForm";
import ExtraCurricularForm from "./ExtraCurricularForm";
import DownloadSOPForm from "./DownloadSOPForm";
import Loader from "./Loader"; // Import the Loader component

import PaymentSuccess from "./PaymentSuccess"; // Import the PaymentSuccess component
import FeatureSection from "./FeatuerSection";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const MultiStepForm = () => {
  const universityFormRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    university: {},
    academics: {},
    experience: [],
    extra_curricular: {},
  });
  const [sopParts, setSopParts] = useState({});
  const [previewText, setPreviewText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);

  const [cookieSet, setCookieSet] = useState(false);

  const steps = [
    { label: "University", icon: <FaArrowRight /> },
    { label: "Academics", icon: <FaArrowRight /> },
    { label: "Work Experience", icon: <FaArrowRight /> },
    { label: "Extra-Curricular", icon: <FaArrowRight /> },
    { label: "Download SOP", icon: <FaArrowRight /> },
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => setRazorpayLoaded(true);
    script.onerror = () =>
      console.error("Razorpay SDK failed to load. Are you online?");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const orderedParts = [
      sopParts.university,
      sopParts.academics,
      sopParts.experience,
      sopParts.extra_curricular,
      sopParts.conclusion,
    ]
      .filter((part) => part)
      .map((part) => part.text)
      .join("\n\n");

    setPreviewText(orderedParts);
  }, [sopParts]);

  useEffect(() => {
    const setCookie = async () => {
      try {
        const response = await fetch("/api/", {
          method: "GET",
          credentials: "include", // Include cookies in the request
        });

        if (response.ok) {
          setCookieSet(true); // Cookie successfully set
        } else {
          console.error("Failed to set cookie.");
        }
      } catch (error) {
        console.error("Error setting cookie:", error);
      }
    };

    setCookie();
  }, []);

  const nextStep = async () => {
    try {
      let isValid = true;
      if (currentStep === 0) {
        isValid = universityFormRef.current.validateForm();
        await saveUniversityData();
      } else if (currentStep === 1) {
        await saveAcademicsData();
      } else if (currentStep === 2) {
        await saveExperienceData();
      } else if (currentStep === 3) {
        await saveExtraCurricularData();
      }

      if (isValid === false) {
        return;
      }

      if (currentStep < steps.length - 1) {
        setCurrentStep((prevStep) => prevStep + 1);
      }

      if (currentStep < steps.length - 1) {
        generateSOP();
      }
    } catch (error) {
      console.error("Error proceeding to the next step:", error);
    }
  };

  const saveUniversityData = async () => {
    await saveData({
      university: formData.university,
      academics: formData.academics,
      experience: formData.experience,
      extra_curricular: formData.extra_curricular,
    });
  };

  const saveAcademicsData = async () => {
    await saveData({
      university: formData.university,
      academics: formData.academics,
      experience: formData.experience,
      extra_curricular: formData.extra_curricular,
    });
  };

  const saveExperienceData = async () => {
    await saveData({
      university: formData.university,
      academics: formData.academics,
      experience: formData.experience,
      extra_curricular: formData.extra_curricular,
    });
  };

  const saveExtraCurricularData = async () => {
    await saveData({
      university: formData.university,
      academics: formData.academics,
      experience: formData.experience,
      extra_curricular: formData.extra_curricular,
    });
  };

  const saveData = async (data) => {
    try {
      const response = await fetch("/api/save_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include", // Include cookies in the request
      });

      if (!response.ok) {
        console.error("Failed to save data.");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const generateSOP = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/preview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include", // Include cookies in the request
      });

      if (response.ok) {
        const data = await response.json();
        setSopParts((prevParts) => ({
          ...prevParts,
          ...data,
        }));
      } else {
        const errorData = await response.json();
        console.error("Error generating SOP:", errorData);
        setPreviewText("Failed to generate SOP. Please try again.");
      }
    } catch (error) {
      console.error("Error generating SOP:", error);
      setPreviewText("Error generating SOP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFormDataChange = (section, data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: data,
    }));
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const extractFilename = (contentDisposition) => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches != null && matches[1]) {
      const filename = matches[1].replace(/['"]/g, "");
      return filename;
    }
    return "statement_of_purpose.pdf";
  };

  var order = null;
  const loadRazorpay = async () => {
    if (!razorpayLoaded) {
      alert("Razorpay SDK is not yet loaded. Please try again.");
      return;
    }

    if (order == null) {
      const response = await fetch("/api/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      order = await response.json();
    }

    try {
      const options = {
        key: order.key_id,
        amount: order.amount,
        currency: order.currency,
        name: "Perfect SOP",
        description: "Purchase SOP Document",
        order_id: order.id,
        handler: async function (response) {
          try {
            await confirmOrder(response);
          } catch (error) {
            console.error("Error confirming order:", error);
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "",
        },
        theme: {
          color: "#007bff",
        },
        modal: {
          ondismiss: function () {
            console.log("Payment popup closed");
          },
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Failed to load Razorpay payment:", error);
      alert("Failed to initiate payment. Please try again.");
    }
  };

  const confirmOrder = async (paymentResponse) => {
    try {
      const response = await fetch("/api/confirm-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          razorpay_order_id: paymentResponse.razorpay_order_id,
          razorpay_payment_id: paymentResponse.razorpay_payment_id,
          razorpay_signature: paymentResponse.razorpay_signature,
        }),
        credentials: "include", // Include cookies in the request
      });

      if (response.ok) {
        previewSop();
      } else {
        console.error("Failed to confirm order.");
      }
    } catch (error) {
      console.error("Error during order confirmation:", error);
    }
  };

  const previewSop = async () => {
    const previewResponse = await fetch("/api/preview", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
      credentials: "include", // Include cookies in the request
    });

    if (previewResponse.ok) {
      const sopParts = await previewResponse.json();
      setSopParts(sopParts);

      // const pdfResponse = await fetch("/api/generate_pdf", {
      //   method: "GET",
      //   credentials: "include", // Include cookies in the request
      // });

      // if (pdfResponse.ok) {
      //   const contentDisposition = pdfResponse.headers.get(
      //     "Content-Disposition"
      //   );
      //   const blob = await pdfResponse.blob();
      //   const url = window.URL.createObjectURL(blob);
      //   const a = document.createElement("a");
      //   a.style.display = "none";
      //   a.href = url;
      //   a.download = extractFilename(contentDisposition);
      //   document.body.appendChild(a);
      //   a.click();
      //   window.URL.revokeObjectURL(url);
      // } else {
      //   console.error("Failed to generate and download PDF.");
      // }
      setIsPaymentCompleted(true);
    } else {
      console.error("Failed to fetch full SOP preview.");
    }
  };

  const renderForm = () => {
    switch (currentStep) {
      case 0:
        return (
          <UniversityForm
            ref={universityFormRef}
            onFormDataChange={handleFormDataChange}
            universityData={formData.university}
          />
        );
      case 1:
        return (
          <AcademicsForm
            onFormDataChange={handleFormDataChange}
            academicsData={formData.academics}
          />
        );
      case 2:
        return (
          <WorkExperienceForm
            onFormDataChange={handleFormDataChange}
            experienceData={formData.experience}
          />
        );
      case 3:
        return (
          <ExtraCurricularForm
            onFormDataChange={handleFormDataChange}
            extraCurricularData={formData.extra_curricular}
          />
        );
      case 4:
        return (
          <DownloadSOPForm
            sopParts={sopParts}
            isPaymentCompleted={isPaymentCompleted}
          />
        );
      default:
        return null;
    }
  };

  if (!cookieSet) {
    return <Loader />;
  }

  const wordCount = previewText.split(/\s+/).length;

  const applyMask = (text, maskIndex) => {
    return (
      <>
        {text.slice(0, maskIndex)}
        <span className="blurred-text">{text.slice(maskIndex)}</span>
      </>
    );
  };
  const downloadPDF = async () => {
    const pdfResponse = await fetch("/api/generate_pdf", {
      method: "GET",
      credentials: "include", // Include cookies in the request
    });

    if (pdfResponse.ok) {
      const contentDisposition = pdfResponse.headers.get("Content-Disposition");
      const blob = await pdfResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = extractFilename(contentDisposition);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Failed to generate and download PDF.");
    }
  };

  const startNewSOP = () => {
    // Reset all form data and state
    setFormData({
      university: {},
      academics: {},
      experience: [],
      extra_curricular: {},
    });
    setSopParts({});
    setCurrentStep(0);
    isPaymentCompleted(false);
  };
  return isPaymentCompleted ? (
    <PaymentSuccess
      sopParts={sopParts}
      downloadPDF={downloadPDF}
      startNewSOP={startNewSOP}
    />
  ) : (
    <div className="container-fluid mt-5">
      <div className="row">
        {/* Right Pane - Testimonials */}
        {currentStep == 0 && (
          <div className="col-md-12" id="right-container">
            <div className="border rounded" style={{ height: "100%" }}>
              {/* Headers before the testimonials */}
              <div className="header-container text-center mb-4">
                <h1 className="header-main">
                  Create Your{" "}
                  <strong className="highlight">Statement of Purpose</strong> in
                  Just 10 Minutes
                </h1>
                <br />
                <br />
                <h2 className="header-sub">
                  With Our <strong className="highlight">AI-Powered</strong> SOP
                  Generator, Achieve the{" "}
                  <strong className="highlight">Perfect Application</strong>
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="stepper-container w-100">
            <Stepper
              steps={steps}
              activeStep={currentStep}
              styleConfig={{
                activeBgColor: "#007bff",
                completedBgColor: "#4caf50",
                inactiveBgColor: "#ddd",
                activeTextColor: "#fff",
                completedTextColor: "#fff",
                inactiveTextColor: "#aaa",
                size: 32,
                circleFontSize: 16,
                lineWidth: 2,
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-8" id="left-container">
          <div className="form-content p-4 border rounded">
            {renderForm()}
            <div className="mt-4 d-flex justify-content-between">
              <button
                className="btn btn-secondary"
                onClick={prevStep}
                disabled={currentStep === 0 || loading}
              >
                <FaArrowLeft className="mr-2" /> Previous
              </button>

              {currentStep === 4 && !isPaymentCompleted && (
                <button
                  className="btn btn-success"
                  //onClick={loadRazorpay}
                  onClick={previewSop}
                  disabled={!razorpayLoaded || loading}
                >
                 Download SOP
                </button>
              )}

              {currentStep < steps.length - 1 && (
                <button
                  className="btn btn-primary"
                  onClick={nextStep}
                  disabled={loading}
                >
                  Next <FaArrowRight className="ml-2" />
                </button>
              )}
            </div>
          </div>
        </div>
        {currentStep == 0 && (
          <div className="col-md-4" id="right-container">
            <div className="border rounded p-3" style={{ height: "100%" }}>
              {/* Headers before the testimonials */}
              <div className="header-container text-left mb-3">
                <h1 className="header-main">
                  Secure Your <strong className="highlight">Future</strong>
                </h1>
                <h2
                  className="header-sub"
                  style={{ fontSize: "1.2rem", fontWeight: "normal" }}
                >
                  Our <strong className="highlight">AI</strong> Will Handle the
                  Rest
                </h2>
              </div>

              {/* Content section */}
              <div className="content-container text-left mt-2">
                <p>
                  Spend just 10 minutes filling out this simple form, and let
                  our AI take care of creating a personalized SOP for you.
                </p>
                <ul className="list-unstyled mb-3">
                  <li>⚡ Quick & Easy Process</li>
                  <li>💻 100% Online</li>
                  <li>📥 Download as PDF</li>
                </ul>
                <p style={{ fontWeight: "bold" }}>
                  Get started now—your dream college is just a few clicks away!
                </p>
              </div>
            </div>
          </div>
        )}
        {/* Right Pane - SOP Preview */}
        {currentStep > 0 && (
          <div className="col-md-4 d-none d-lg-block" id="right-container">
            <div
              className="sop-preview p-4 border rounded bg-light document d-flex flex-column"
              style={{
                height: "80vh",
                overflowY: "auto",
                position: "relative",
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <h5
                    style={{
                      marginBottom: "100px",
                      color: "#333",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Your SOP is generating, it may take a minute.
                  </h5>
                  <Loader setLoading={setLoading} />
                </div>
              ) : (
                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "justify",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "24px", // Font size adjusted to fit in one line
                        fontWeight: "bold",
                        margin: 0,
                        flex: 1, // Make the heading take available space
                      }}
                    >
                      Statement of Purpose
                    </h4>
                    <div
                      className="badge badge-info"
                      style={{
                        backgroundColor: "#17a2b8",
                        color: "#fff",
                        padding: "10px 15px",
                        fontSize: "14px",
                        borderRadius: "5px",
                        marginLeft: "10px",
                        marginTop: "5px", // Slight margin to avoid overlap
                      }}
                    >
                      Word Count: {wordCount}
                    </div>
                  </div>

                  <div className="alert alert-warning text-center">
                    As you keep on adding the data, the SOP will become more
                    interesting.
                  </div>

                  <div style={{ flex: 1, overflowY: "auto" }}>
                    {sopParts.university && (
                      <p>
                        {applyMask(
                          sopParts.university.text,
                          sopParts.university.mask_start_index
                        )}
                      </p>
                    )}
                    {sopParts.academics && (
                      <p>
                        {applyMask(
                          sopParts.academics.text,
                          sopParts.academics.mask_start_index
                        )}
                      </p>
                    )}
                    {sopParts.experience && (
                      <p>
                        {applyMask(
                          sopParts.experience.text,
                          sopParts.experience.mask_start_index
                        )}
                      </p>
                    )}
                    {sopParts.extra_curricular && (
                      <p>
                        {applyMask(
                          sopParts.extra_curricular.text,
                          sopParts.extra_curricular.mask_start_index
                        )}
                      </p>
                    )}
                    {sopParts.conclusion && (
                      <p>
                        {applyMask(
                          sopParts.conclusion.text,
                          sopParts.conclusion.mask_start_index
                        )}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {currentStep == 0 && (
        <div>
          <FeatureSection />
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
