import React, { useState, useEffect } from 'react';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import Loader from './Loader'; // Assuming you have the Loader component

const ExtraCurricularForm = ({ onFormDataChange, extraCurricularData }) => {
    const [organizations, setOrganizations] = useState(extraCurricularData.organizations || [{ name: '', description: '', role: '', responsibilities: '' }]);
    const [communityService, setCommunityService] = useState(extraCurricularData.communityService || [{ service: '' }]);
    const [hobbies, setHobbies] = useState(extraCurricularData.hobbies || [{ hobby: '' }]);
    const [achievements, setAchievements] = useState(extraCurricularData.achievements || [{ achievement: '' }]);

    useEffect(() => {
        onFormDataChange('extra_curricular', {
            organizations,
            communityService,
            hobbies,
            achievements,
        });
    }, [organizations, communityService, hobbies, achievements]);

    const handleOrganizationChange = (index, event) => {
        const newOrganizations = [...organizations];
        newOrganizations[index][event.target.name] = event.target.value;
        setOrganizations(newOrganizations);
    };

    const handleCommunityServiceChange = (index, event) => {
        const newCommunityService = [...communityService];
        newCommunityService[index][event.target.name] = event.target.value;
        setCommunityService(newCommunityService);
    };

    const handleHobbyChange = (index, event) => {
        const newHobbies = [...hobbies];
        newHobbies[index][event.target.name] = event.target.value;
        setHobbies(newHobbies);
    };

    const handleAchievementChange = (index, event) => {
        const newAchievements = [...achievements];
        newAchievements[index][event.target.name] = event.target.value;
        setAchievements(newAchievements);
    };

    const addOrganization = () => {
        setOrganizations([...organizations, { name: '', description: '', role: '', responsibilities: '' }]);
    };

    const addCommunityService = () => {
        setCommunityService([...communityService, { service: '' }]);
    };

    const addHobby = () => {
        setHobbies([...hobbies, { hobby: '' }]);
    };

    const addAchievement = () => {
        setAchievements([...achievements, { achievement: '' }]);
    };

    const removeOrganization = (index) => {
        const newOrganizations = [...organizations];
        newOrganizations.splice(index, 1);
        setOrganizations(newOrganizations);
    };

    const removeCommunityService = (index) => {
        const newCommunityService = [...communityService];
        newCommunityService.splice(index, 1);
        setCommunityService(newCommunityService);
    };

    const removeHobby = (index) => {
        const newHobbies = [...hobbies];
        newHobbies.splice(index, 1);
        setHobbies(newHobbies);
    };

    const removeAchievement = (index) => {
        const newAchievements = [...achievements];
        newAchievements.splice(index, 1);
        setAchievements(newAchievements);
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-4">
            Extra-Curricular
            </h2>
            <form>
                {/* Organizations and Clubs */}
                <div className="mb-4">
                    <label>Organizations and Clubs</label>
                    {organizations.map((organization, index) => (
                        <div key={index} className="border rounded p-3 mb-3 bg-light">
                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor={`organizationName-${index}`}>Organization / Club Name *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`organizationName-${index}`}
                                        name="name"
                                        value={organization.name}
                                        onChange={(e) => handleOrganizationChange(index, e)}
                                        placeholder="Eg: Google Developer Student Club"
                                    />
                                </div>
                                <div className="col text-right">
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() => removeOrganization(index)}
                                    >
                                        <FaTrashAlt />
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor={`organizationDescription-${index}`}>Organization / Club Description</label>
                                <textarea
                                    className="form-control"
                                    id={`organizationDescription-${index}`}
                                    name="description"
                                    rows="2"
                                    value={organization.description}
                                    onChange={(e) => handleOrganizationChange(index, e)}
                                    placeholder="Eg: Google Developer Student Club is a community of students who are passionate about learning and teaching others about technology."
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor={`organizationRole-${index}`}>Your Role</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`organizationRole-${index}`}
                                    name="role"
                                    value={organization.role}
                                    onChange={(e) => handleOrganizationChange(index, e)}
                                    placeholder="Eg: Team Lead"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor={`organizationResponsibilities-${index}`}>Your Responsibilities</label>
                                <textarea
                                    className="form-control"
                                    id={`organizationResponsibilities-${index}`}
                                    name="responsibilities"
                                    rows="2"
                                    value={organization.responsibilities}
                                    onChange={(e) => handleOrganizationChange(index, e)}
                                    placeholder="Eg: Organizing events, managing team members, etc."
                                />
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-primary mb-3" onClick={addOrganization}>
                        <FaPlusCircle className="mr-2" /> New Organization/Club
                    </button>
                </div>

                {/* Community Service */}
                <div className="mb-4">
                    <label>Community Service</label>
                    {communityService.map((service, index) => (
                        <div key={index} className="input-group mb-3">
                            <textarea
                                className="form-control"
                                id={`communityService-${index}`}
                                name="service"
                                rows="2"
                                value={service.service}
                                onChange={(e) => handleCommunityServiceChange(index, e)}
                                placeholder="Eg: Team lead at an NGO that helped underprivileged children get access to education"
                            />
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => removeCommunityService(index)}
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-primary mb-3" onClick={addCommunityService}>
                        <FaPlusCircle className="mr-2" /> Add Experience
                    </button>
                </div>

                {/* Hobbies */}
                <div className="mb-4">
                    <label>Hobbies</label>
                    {hobbies.map((hobby, index) => (
                        <div key={index} className="input-group mb-3">
                            <textarea
                                className="form-control"
                                id={`hobby-${index}`}
                                name="hobby"
                                rows="2"
                                value={hobby.hobby}
                                onChange={(e) => handleHobbyChange(index, e)}
                                placeholder="Eg: Enjoy capturing moments through photography"
                            />
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => removeHobby(index)}
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-primary mb-3" onClick={addHobby}>
                        <FaPlusCircle className="mr-2" /> Add Hobby
                    </button>
                </div>

                {/* Awards and Achievements */}
                <div className="mb-4">
                    <label>Awards and Achievements</label>
                    {achievements.map((achievement, index) => (
                        <div key={index} className="input-group mb-3">
                            <textarea
                                className="form-control"
                                id={`achievement-${index}`}
                                name="achievement"
                                rows="2"
                                value={achievement.achievement}
                                onChange={(e) => handleAchievementChange(index, e)}
                                placeholder="Eg: Represented school in Model United Nations 2019"
                            />
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => removeAchievement(index)}
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-primary mb-3" onClick={addAchievement}>
                        <FaPlusCircle className="mr-2" /> Add Achievement
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ExtraCurricularForm;
