import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mt-5">
            <h1>Privacy Policy</h1>
            <p>Last updated: 27th August 2024</p>
            <p>
                This Privacy Policy outlines our policies and procedures regarding the collection, use, and disclosure of your information when you use our Service. It also explains your privacy rights and how the law protects you.
            </p>
            <p>
                We use your personal data to provide and improve our Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.
            </p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>
                Words with capitalized initial letters have meanings defined under the following conditions. These definitions shall have the same meaning whether they appear in singular or plural form.
            </p>
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li><strong>Account:</strong> A unique account created for you to access our Service or parts of our Service.</li>
                <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
                <li><strong>Team:</strong> (referred to as either  "We", "Us" or "Our" in this Agreement) refers to Perfect SOP, Pbel City, Appa Junction, Hyderabad, Telangana, India - 500089.</li>
                <li><strong>Cookies:</strong> Small files placed on your device by a website, containing details of your browsing history on that website among its many uses.</li>
                <li><strong>Country:</strong> Refers to Telangana, India.</li>
                <li><strong>Device:</strong> Any device that can access the Service, such as a computer, cellphone, or digital tablet.</li>
                <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
                <li><strong>Service:</strong> Refers to the Website.</li>
                <li><strong>Service Provider:</strong> Any natural or legal person who processes data on behalf of the Team, such as third-party companies or individuals employed by the Perfect SOP Team to facilitate the Service.</li>
                <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (e.g., the duration of a page visit).</li>
                <li><strong>Website:</strong> Refers to Perfect SOP, accessible from <a href="https://www.perfectsop.com">https://www.perfectsop.com</a>.</li>
                <li><strong>You:</strong> The individual accessing or using the Service, or the Perfect SOP team or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>
            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Usage Data</li>
            </ul>
            <h4>Usage Data</h4>
            <p>
                Usage Data is collected automatically when using the Service. This may include information such as your device's IP address, browser type, browser version, the pages you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
            </p>
            <p>
                When you access the Service by or through a mobile device, we may collect certain information automatically, including but not limited to the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.
            </p>
            <h3>Tracking Technologies and Cookies</h3>
            <p>
                We use Cookies and similar tracking technologies to track activity on our Service and store certain information. These technologies include beacons, tags, and scripts to collect and track information and to improve and analyze our Service. The technologies we use may include:
            </p>
            <ul>
                <li><strong>Cookies or Browser Cookies:</strong> A small file placed on your device. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service.</li>
                <li><strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons that allow the Perfect SOP Team to count users who have visited those pages or opened an email and for other related website statistics.</li>
            </ul>
            <p>We use both Session and Persistent Cookies for the following purposes:</p>
            <ul>
                <li><strong>Necessary / Essential Cookies:</strong> These are session cookies essential to provide you with services available through the Website and to enable you to use some of its features.</li>
                <li><strong>Cookies Policy / Notice Acceptance Cookies:</strong> These persistent cookies identify if users have accepted the use of cookies on the Website.</li>
                <li><strong>Functionality Cookies:</strong> These persistent cookies allow us to remember choices you make when you use the Website, such as your login details or language preference.</li>
            </ul>
            <h3>Use of Your Personal Data</h3>
            <p>The Perfect SOP Team may use Personal Data for the following purposes:</p>
            <ul>
                <li><strong>To provide and maintain our Service:</strong> Including monitoring the usage of our Service.</li>
                <li><strong>To manage Your Account:</strong> To manage your registration as a user of the Service.</li>
                <li><strong>For the performance of a contract:</strong> The development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased.</li>
                <li><strong>To contact You:</strong> By email, telephone calls, SMS, or other forms of electronic communication regarding updates or information related to functionalities, products, or services.</li>
                <li><strong>To provide You with news and special offers:</strong> Unless you have opted not to receive such information.</li>
                <li><strong>To manage Your requests:</strong> To attend and manage your requests to us.</li>
                <li><strong>For business transfers:</strong> We may use your information to evaluate or conduct a merger, sale, restructuring, or other transfer of our assets.</li>
                <li><strong>For other purposes:</strong> Such as data analysis, identifying usage trends, evaluating and improving our Service, products, services, and your experience.</li>
            </ul>
            <h3>Retention of Your Personal Data</h3>
            <p>
                The Perfect SOP Team will retain your personal data only for as long as necessary for the purposes outlined in this Privacy Policy. We will retain and use your data as needed to comply with legal obligations, resolve disputes, and enforce our legal agreements and policies.
            </p>
            <h3>Transfer of Your Personal Data</h3>
            <p>
                Your information, including personal data, may be processed at the Perfect SOP Team’s operating offices and any other locations where the parties involved in the processing are located. This may involve transferring your data to computers located outside of your jurisdiction, where data protection laws may differ. 
            </p>
            <h3>Delete Your Personal Data</h3>
            <p>
                You have the right to request the deletion of the personal data we have collected about you. Our Service may allow you to delete certain information within the Service. You may also update, amend, or delete your information by contacting us.
            </p>
            <h3>Disclosure of Your Personal Data</h3>
            <p>We may disclose your personal data under the following circumstances:</p>
            <ul>
                <li><strong>Business Transactions:</strong> If the Perfect SOP Team is involved in a merger, acquisition, or asset sale, your personal data may be transferred.</li>
                <li><strong>Law Enforcement:</strong> The Perfect SOP Team may disclose your data if required by law or in response to valid requests by public authorities.</li>
                <li><strong>Other Legal Requirements:</strong> We may disclose your data to comply with legal obligations, protect our rights or property, prevent wrongdoing, protect the safety of our users, or defend against legal liability.</li>
            </ul>
            <h3>Security of Your Personal Data</h3>
            <p>
                The security of your data is important to us, but no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security.
            </p>
            <h3>Children's Privacy</h3>
            <p>
                Our Service is not intended for anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under 13. If you are a parent or guardian and believe your child has provided us with personal data, please contact us.
            </p>
            <h3>Links to Other Websites</h3>
            <p>
                Our Service may contain links to other websites not operated by us. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites.
            </p>
            <h3>Changes to this Privacy Policy</h3>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page, updating the "Last updated" date, and, if necessary, informing you via email or a prominent notice on our Service.
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when posted on this page.
            </p>
            <h3>Contact Us</h3>
            <p>
                If you have any questions about this Privacy Policy, you can contact us by email at <a href="mailto:contact@perfectsop.com">contact@perfectsop.com</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
