import React, { useState, useEffect } from 'react';
import './../styles/loader.css';

const Loader = ({ setLoading }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval;

        if (progress < 50) {
            interval = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress >= 50) {
                        clearInterval(interval);
                        return 50;
                    }
                    return Math.min(oldProgress + 10, 50);
                });
            }, 800); // Fast increase until 50%
        } else if (progress >= 50 && progress < 100) {
            interval = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress >= 100) {
                        clearInterval(interval);
                        setLoading(false); // Set loading to false immediately after reaching 100%
                        return 100;
                    }
                    return Math.min(oldProgress + 2, 100); // Slow increase after 50%
                });
            }, 1000); // Slow interval after 50%
        }

        return () => {
            clearInterval(interval);
        };
    }, [progress, setLoading]);

    return (
        <div className="loader-container">
            <div className="progress-bar">
                <div
                    className="progress"
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
            <div className="progress-text">{progress}%</div>
        </div>
    );
};

export default Loader;
