import React, { useState, useEffect } from 'react';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

const WorkExperienceForm = ({ onFormDataChange, experienceData }) => {
    const [workExperiences, setWorkExperiences] = useState(experienceData || [{
        companyName: '',
        description: '',
        skills: '',
        roles: [{
            jobTitle: '',
            responsibilities: '',
            projects: ''
        }]
    }]);

    useEffect(() => {
        onFormDataChange('experience', workExperiences);
    }, [workExperiences]);

    const handleExperienceChange = (index, event) => {
        const newWorkExperiences = [...workExperiences];
        newWorkExperiences[index][event.target.name] = event.target.value;
        setWorkExperiences(newWorkExperiences);
    };

    const handleRoleChange = (expIndex, roleIndex, event) => {
        const newWorkExperiences = [...workExperiences];
        newWorkExperiences[expIndex].roles[roleIndex][event.target.name] = event.target.value;
        setWorkExperiences(newWorkExperiences);
    };

    const addExperience = () => {
        setWorkExperiences([...workExperiences, {
            companyName: '',
            description: '',
            skills: '',
            roles: [{
                jobTitle: '',
                responsibilities: '',
                projects: ''
            }]
        }]);
    };

    const removeExperience = (index) => {
        const newWorkExperiences = [...workExperiences];
        newWorkExperiences.splice(index, 1);
        setWorkExperiences(newWorkExperiences);
    };

    const addRole = (expIndex) => {
        const newWorkExperiences = [...workExperiences];
        newWorkExperiences[expIndex].roles.push({
            jobTitle: '',
            responsibilities: '',
            projects: ''
        });
        setWorkExperiences(newWorkExperiences);
    };

    const removeRole = (expIndex, roleIndex) => {
        const newWorkExperiences = [...workExperiences];
        newWorkExperiences[expIndex].roles.splice(roleIndex, 1);
        setWorkExperiences(newWorkExperiences);
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-4">Work Experience</h2>

            <div className="alert alert-info">
                You can skip this part if you don’t have any work experience. Click <strong>Next</strong> to continue.
            </div>

            <form>
                {workExperiences.map((experience, expIndex) => (
                    <div key={expIndex} className="border rounded p-4 mb-4">
                        {/* Company and Description */}
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor={`companyName-${expIndex}`}>Company / Organization name? *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`companyName-${expIndex}`}
                                    name="companyName"
                                    value={experience.companyName}
                                    onChange={(e) => handleExperienceChange(expIndex, e)}
                                    placeholder="Eg: Amazon"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor={`description-${expIndex}`}>Describe the organization</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`description-${expIndex}`}
                                    name="description"
                                    value={experience.description}
                                    onChange={(e) => handleExperienceChange(expIndex, e)}
                                    placeholder="Eg: One of the largest e-commerce companies in the world."
                                />
                            </div>
                        </div>

                        {/* Skills */}
                        <div className="form-group mb-3">
                            <label htmlFor={`skills-${expIndex}`}>What skills did you learn in this role?</label>
                            <input
                                type="text"
                                className="form-control"
                                id={`skills-${expIndex}`}
                                name="skills"
                                value={experience.skills}
                                onChange={(e) => handleExperienceChange(expIndex, e)}
                                placeholder="Eg: JavaScript, Python, ReactJs, NodeJs"
                            />
                        </div>

                        {/* Roles */}
                        {experience.roles.map((role, roleIndex) => (
                            <div key={roleIndex} className="border rounded p-3 mb-3 bg-light">
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor={`jobTitle-${expIndex}-${roleIndex}`}>Job title? *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={`jobTitle-${expIndex}-${roleIndex}`}
                                            name="jobTitle"
                                            value={role.jobTitle}
                                            onChange={(e) => handleRoleChange(expIndex, roleIndex, e)}
                                            placeholder="Eg: Software Engineer"
                                        />
                                    </div>
                                    <div className="col text-right">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => removeRole(expIndex, roleIndex)}
                                        >
                                            <FaTrashAlt />
                                        </button>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor={`responsibilities-${expIndex}-${roleIndex}`}>Responsibilities / Job Description</label>
                                    <textarea
                                        className="form-control"
                                        id={`responsibilities-${expIndex}-${roleIndex}`}
                                        name="responsibilities"
                                        rows="2"
                                        value={role.responsibilities}
                                        onChange={(e) => handleRoleChange(expIndex, roleIndex, e)}
                                        placeholder="Eg: I was responsible for building & maintaining the backend of the eCommerce application."
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor={`projects-${expIndex}-${roleIndex}`}>What projects did you work on in this role?</label>
                                    <textarea
                                        className="form-control"
                                        id={`projects-${expIndex}-${roleIndex}`}
                                        name="projects"
                                        rows="2"
                                        value={role.projects}
                                        onChange={(e) => handleRoleChange(expIndex, roleIndex, e)}
                                        placeholder="Eg: Built a chat application using NodeJs, WebSocket, and Redis."
                                    />
                                </div>
                            </div>
                        ))}

                        <button
                            type="button"
                            className="btn btn-outline-primary mb-3"
                            onClick={() => addRole(expIndex)}
                        >
                            <FaPlusCircle className="mr-2" /> Add Role
                        </button>

                        {workExperiences.length > 1 && (
                            <button
                                type="button"
                                className="btn btn-outline-danger float-right"
                                onClick={() => removeExperience(expIndex)}
                            >
                                <FaTrashAlt /> Remove Experience
                            </button>
                        )}
                    </div>
                ))}
                <button type="button" className="btn btn-outline-primary" onClick={addExperience}>
                    <FaPlusCircle className="mr-2" /> Add Work Experience
                </button>
            </form>
        </div>
    );
};

export default WorkExperienceForm;
