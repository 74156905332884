import React from "react";
import Header from "./../components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../styles/professional.css";
import MultiStepForm from "./../components/MultiStepForm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Footer from "./../components/Footer";
import TermsOfService from "./../components/TermsOfService";
import PrivacyPolicy from "./../components/PrivacyPolicy";
import RefundPolicy from "./../components/RefundPolicy";
const Home = () => {
  return (
    <div>
      <Header />
      <main>
        <Router>
          <Routes>
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/" element={<MultiStepForm />} />
          </Routes>
        </Router>
        <br />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
