import React from "react";

const DownloadSOPForm = () => {
  return (
    <div>
      <h1
        style={{
          color: "#007bff",
          fontSize: "28px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        🎓 Get Your SOP for Free Until September 10th!
      </h1>

      <p style={{ fontSize: "18px", color: "#333", lineHeight: "1.6" }}>
        For a limited time only, get your professionally crafted Statement of
        Purpose (SOP) absolutely free until <strong>September 10th</strong>.
        Elevate your application with a personalized SOP that will capture the
        attention of admissions committees.
      </p>

      <div className="benefits mt-4" style={{ marginTop: "30px" }}>
        <h4
          style={{
            color: "#007bff",
            fontSize: "22px",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          ✨ Why Choose Our SOP?
        </h4>
        <ul
          style={{
            listStyleType: "disc",
            marginLeft: "20px",
            color: "#555",
            fontSize: "16px",
          }}
        >
          <li style={{ marginBottom: "10px" }}>
            💼 Unique and tailored to your personal journey.
          </li>
          <li style={{ marginBottom: "10px" }}>
            📚 Expertly written to make a lasting impression.
          </li>
          <li style={{ marginBottom: "10px" }}>
            🚀 Essential for creating a standout application.
          </li>
        </ul>
      </div>

      <div
        className="action mt-4"
        style={{ marginTop: "40px", textAlign: "center" }}
      >
        <p style={{ fontSize: "20px", color: "#333", fontWeight: "bold" }}>
          Don’t miss out on this exclusive offer! Get your top-quality SOP
          absolutely free until September 10th!
        </p>
      </div>
    </div>
  );
};

export default DownloadSOPForm;
