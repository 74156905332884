import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './../img/perfect_sop_logo.png';
import { Modal, Button, Navbar, Nav } from 'react-bootstrap';

const Header = () => {
    const [showContact, setShowContact] = useState(false);
    const [showHomeWarning, setShowHomeWarning] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleCloseContact = () => setShowContact(false);
    const handleShowContact = () => {
        setShowContact(true);
        setExpanded(false); // Close navbar when contact modal is opened
    };

    const handleCloseHomeWarning = () => setShowHomeWarning(false);
    const handleShowHomeWarning = () => {
        setShowHomeWarning(true);
        setExpanded(false); // Close navbar when warning modal is opened
    };

    const handleRefreshPage = () => {
        window.location.href = '/';
    };

    return (
        <>
            <header style={{
                background: 'linear-gradient(to right, #ffffff, #f1f8ff)',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                padding: '10px 0'
            }}>
                <div className="container">
                    <Navbar expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <Navbar.Brand href="#" className="d-flex align-items-center">
                                <img src={logo} alt="Perfect SOP Logo" style={{ height: '35px', marginRight: '10px' }} />
                                <span className="d-none d-lg-block" style={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    color: '#0073e6'
                                }}>Crafting Your Future</span>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'transparent' }} />
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto text-center">
                                <Nav.Link href="#" onClick={(e) => { e.preventDefault(); handleShowHomeWarning(); }} style={{
                                    color: '#004080',
                                    fontWeight: '500',
                                    padding: '10px',
                                    transition: 'color 0.3s ease',
                                }}>
                                    Home
                                </Nav.Link>

                                <Nav.Link href="#contact" onClick={handleShowContact} style={{
                                    color: '#004080',
                                    fontWeight: '500',
                                    padding: '10px',
                                    transition: 'color 0.3s ease',
                                }}>
                                    Contact
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </header>

            {/* Contact Modal */}
            <Modal show={showContact} onHide={handleCloseContact} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>For any queries, please mail us at <a href="mailto:contact@perfectsop.com">contact@perfectsop.com</a>. We will get back to you within 24 hours.</p>
                    <br />
                    <br />
                    <br />
                    <p>Our Operating address:<br />
                        Pbel City,<br />
                        Appa Junction,<br />
                        Hyderabad, Telangana, India - 500089
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseContact}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Home Warning Modal */}
            <Modal show={showHomeWarning} onHide={handleCloseHomeWarning} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to refresh the page? Any unsaved changes will be lost.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHomeWarning}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleRefreshPage}>
                        Yes, Refresh
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Header;
