// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FeedbackModal.css */
.star-rating {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.star-rating button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.star-rating .star {
    font-size: 35px;
    color: #e4e5e9; /* Default grey color for stars */
    transition: color 0.3s;
}

.star-rating .star:hover,
.star-rating .star.hover,
.star-rating .star.selected {
    color: #ffc107; /* Yellow color for selected and hovered stars */
}
`, "",{"version":3,"sources":["webpack://./src/styles/feedback.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc,EAAE,iCAAiC;IACjD,sBAAsB;AAC1B;;AAEA;;;IAGI,cAAc,EAAE,gDAAgD;AACpE","sourcesContent":["/* FeedbackModal.css */\n.star-rating {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 10px;\n}\n\n.star-rating button {\n    background: none;\n    border: none;\n    padding: 0;\n    cursor: pointer;\n}\n\n.star-rating .star {\n    font-size: 35px;\n    color: #e4e5e9; /* Default grey color for stars */\n    transition: color 0.3s;\n}\n\n.star-rating .star:hover,\n.star-rating .star.hover,\n.star-rating .star.selected {\n    color: #ffc107; /* Yellow color for selected and hovered stars */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
