import React from 'react';

const RefundPolicy = () => {
    return (
        <div className="container mt-5">
            <h1>Refund Policy</h1>
            <p>
                We do not provide refunds based on dissatisfaction with the generated SOP, as we offer a partial preview before payment. We strongly encourage you to carefully review the preview before finalizing your purchase.
            </p>
            <p>
                Refund requests must be submitted within 30 days of the purchase date. To request a refund, please email us at <a href="mailto:contact@perfectsop.com">contact@perfectsop.com</a>. Refunds are typically processed within 7 business days.
            </p>
            <p>
                Refunds are generally issued under the following circumstances:
            </p>
            <ul>
                <li>You were charged multiple times for the same SOP.</li>
                <li>The SOP you purchased was not delivered.</li>
                <li>The SOP contains factual inaccuracies (e.g., incorrect university name or course name) despite correct information being provided.</li>
            </ul>
        </div>
    );
};

export default RefundPolicy;
