import React, { useState, useEffect } from 'react';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

const AcademicsForm = ({ onFormDataChange, academicsData }) => {
    const [projects, setProjects] = useState(academicsData.projects || [{ project: '' }]);
    const [achievements, setAchievements] = useState(academicsData.achievements || [{ achievement: '' }]);

    useEffect(() => {
        onFormDataChange('academics', {
            ...academicsData,
            projects,
            achievements
        });
    }, [projects, achievements]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onFormDataChange('academics', {
            ...academicsData,
            [name]: value,
            projects,
            achievements
        });
    };

    const handleProjectChange = (index, event) => {
        const newProjects = [...projects];
        newProjects[index][event.target.name] = event.target.value;
        setProjects(newProjects);
    };

    const handleAchievementChange = (index, event) => {
        const newAchievements = [...achievements];
        newAchievements[index][event.target.name] = event.target.value;
        setAchievements(newAchievements);
    };

    const addProject = () => {
        setProjects([...projects, { project: '' }]);
    };

    const addAchievement = () => {
        setAchievements([...achievements, { achievement: '' }]);
    };

    const removeProject = (index) => {
        const newProjects = [...projects];
        newProjects.splice(index, 1);
        setProjects(newProjects);
    };

    const removeAchievement = (index) => {
        const newAchievements = [...achievements];
        newAchievements.splice(index, 1);
        setAchievements(newAchievements);
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-4">Academics</h2>
            <form>
                {/* Degree and Major */}
                <div className="row mb-3">
                    <div className="col">
                        <label htmlFor="highestDegree">What is your highest degree? *</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="highestDegree" 
                            name="degree"
                            value={academicsData.degree || ''} 
                            onChange={handleInputChange} 
                            placeholder="Eg: Bachelor of Engineering" 
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="majorField">What was your major / field of study? *</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="majorField" 
                            name="major"
                            value={academicsData.major || ''} 
                            onChange={handleInputChange} 
                            placeholder="Eg: Electronics and Communication" 
                        />
                    </div>
                </div>

                {/* University and GPA */}
                <div className="row mb-3">
                    <div className="col">
                        <label htmlFor="university">University you studied in: *</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="university" 
                            name="university"
                            value={academicsData.university || ''} 
                            onChange={handleInputChange} 
                            placeholder="Eg: Indian Institute of Technology, Delhi" 
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="gpa">CGPA:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="gpa" 
                            name="GPA"
                            value={academicsData.GPA || ''} 
                            onChange={handleInputChange} 
                            placeholder="Eg: 8.5" 
                        />
                        <small className="form-text text-muted">Leave empty if you don't want it to be mentioned in the SOP</small>
                    </div>
                </div>

                {/* Relevant Courses */}
                <div className="form-group mb-3">
                    <label htmlFor="relevantCourses">Relevant courses:</label>
                    <textarea 
                        className="form-control" 
                        id="relevantCourses" 
                        rows="3" 
                        name="courses"
                        value={academicsData.courses || ''} 
                        onChange={handleInputChange} 
                        placeholder="Eg: Machine Learning, Cloud Computing, Internet of Things"
                    ></textarea>
                    <small className="form-text text-muted">Ideally only mention courses that are relevant to the program you are applying to.</small>
                </div>

                {/* Projects */}
                <div className="mb-3">
                    <label>Projects</label>
                    {projects.map((project, index) => (
                        <div key={index} className="input-group mb-2">
                            <input
                                type="text"
                                className="form-control mr-1"
                                placeholder="Eg: Developed a smart irrigation system using IoT and cloud integration."
                                name="project"
                                value={project.project}
                                onChange={(e) => handleProjectChange(index, e)}
                            />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-outline-danger" onClick={() => removeProject(index)}>
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-primary" onClick={addProject}>
                        <FaPlusCircle className="mr-2" /> Add Project
                    </button>
                </div>

                {/* Academic Achievements */}
                <div className="mb-3">
                    <label>Academic Achievements</label>
                    {achievements.map((achievement, index) => (
                        <div key={index} className="input-group mb-2">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Eg: Ranked among the top 1% in JEE Advanced 2020."
                                name="achievement"
                                value={achievement.achievement}
                                onChange={(e) => handleAchievementChange(index, e)}
                            />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-outline-danger" onClick={() => removeAchievement(index)}>
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-primary" onClick={addAchievement}>
                        <FaPlusCircle className="mr-2" /> Add Academic Achievement
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AcademicsForm;
