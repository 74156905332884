import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Loader from "./Loader"; // Assuming you have the Loader component
import countryList from "react-select-country-list";
import Select from "react-select";

const UniversityForm = forwardRef(
  ({ onFormDataChange, universityData, loading }, ref) => {
    const [formValues, setFormValues] = useState(universityData);
    const [errors, setErrors] = useState({});
    const [customGoal, setCustomGoal] = useState(
      universityData.customGoal || ""
    );
    const [selectedGoals, setSelectedGoals] = useState(
      universityData.pursuement_goal || []
    );
    const [customCriteria, setCustomCriteria] = useState(
      universityData.customCriteria || ""
    );
    const [selectedCriteria, setSelectedCriteria] = useState(
      universityData.university_qualities || []
    );

    useEffect(() => {
      // If custom is selected, ensure customGoal is appended as a single string.
      const updatedGoals = selectedGoals.includes("custom")
        ? [...selectedGoals.filter((goal) => goal !== "custom"), customGoal]
        : selectedGoals;

      onFormDataChange("university", {
        ...universityData,
        pursuement_goal: updatedGoals,
        university_qualities: selectedCriteria.includes("custom")
          ? [
              ...selectedCriteria.filter((criteria) => criteria !== "custom"),
              customCriteria,
            ]
          : selectedCriteria,
      });
    }, [selectedGoals, customGoal, selectedCriteria, customCriteria]);

    const countries = countryList().getData(); // Get country list with flags

    const handleCountryChange = (selectedOption) => {
      setFormValues({ ...formValues, country: selectedOption });
      if (selectedOption && selectedOption.value) {
        onFormDataChange("university", {
          ...universityData,
          country: selectedOption.value,
        });
      }
    };

    const handleGoalChange = (e) => {
      const { value, checked } = e.target;
      setSelectedGoals(
        checked
          ? [...selectedGoals, value]
          : selectedGoals.filter((goal) => goal !== value)
      );
    };

    const handleCriteriaChange = (e) => {
      const { value, checked } = e.target;
      setSelectedCriteria(
        checked
          ? [...selectedCriteria, value]
          : selectedCriteria.filter((criteria) => criteria !== value)
      );
    };

    const handleCustomGoalChange = (e) => {
      setCustomGoal(e.target.value);
    };

    const handleCustomCriteriaChange = (e) => {
      setCustomCriteria(e.target.value);
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
      onFormDataChange("university", {
        ...universityData,
        [name]: value,
      });

      // Clear the error for this field if it is now valid
      if (name === "full_name") {
        if (value.trim()) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "", // Clear the error for the current field
          }));
        }
      }

      if (name === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Please enter a valid email address",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        }
      }
    };

    const validate = () => {
      const newErrors = {};
      if (!formValues.full_name) {
        newErrors.full_name = "Full name is required";
      }
      if (!formValues.email) {
        newErrors.email = "Email is required";
      } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formValues.email)) {
          newErrors.email = "Please enter a valid email address";
        }
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    useImperativeHandle(ref, () => ({
      validateForm: validate,
    }));

    return (
      <div className="container mt-5">
        {loading && <Loader />} {/* Display the loader when loading is true */}
        <h2 className="text-center mb-4">
          Let's start creating your Statement of Purpose!
        </h2>
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="college">
                  Which college/university are you applying to?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="college"
                  name="college"
                  value={universityData.college || ""}
                  onChange={handleInputChange}
                  placeholder="e.g. University of Windsor"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="country">
                  Which country is the college/university located in?
                </label>
                <Select
                  id="country"
                  name="country"
                  value={countries.find(
                    (country) => country.value === formValues.country
                  )}
                  onChange={handleCountryChange}
                  options={countries}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Please select a country"
                  isClearable
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="program">
                  What program are you applying for?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="program"
                  name="program"
                  value={universityData.program || ""}
                  onChange={handleInputChange}
                  placeholder="e.g. Master in Software Engineering"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="full_name">
                  Your Full Name
                  <span className="text-danger ml-1">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.full_name ? "border-danger" : ""
                  }`}
                  id="full_name"
                  name="full_name"
                  value={universityData.full_name || ""}
                  onChange={handleInputChange}
                  placeholder="Enter your full name"
                  required
                />
                {errors.full_name && (
                  <small className="text-danger">{errors.full_name}</small>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="email">
                  Your Email ID
                  <span className="text-danger ml-1">*</span>
                </label>
                <input
                  type="email"
                  className={`form-control ${
                    errors.email ? "border-danger" : ""
                  }`}
                  id="email"
                  name="email"
                  value={universityData.email || ""}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                  required
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="pursuement_goal">
                  What long-term goals do you want to achieve by pursuing this
                  program? (Select all that apply)
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal1"
                    value="Contribute to societal development by addressing key challenges."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Contribute to societal development by addressing key challenges."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal1">
                    Contribute to societal development by addressing key
                    challenges.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal2"
                    value="Become a thought leader and inspire others in my industry."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Become a thought leader and inspire others in my industry."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal2">
                    Become a thought leader and inspire others in my industry.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal3"
                    value="Achieve personal and professional growth through continuous learning."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Achieve personal and professional growth through continuous learning."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal3">
                    Achieve personal and professional growth through continuous
                    learning.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal4"
                    value="Build a strong network of professionals and experts globally."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Build a strong network of professionals and experts globally."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal4">
                    Build a strong network of professionals and experts
                    globally.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal5"
                    value="Create innovative solutions that positively impact communities."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Create innovative solutions that positively impact communities."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal5">
                    Create innovative solutions that positively impact
                    communities.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal6"
                    value="Enhance my leadership skills to guide teams effectively."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Enhance my leadership skills to guide teams effectively."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal6">
                    Enhance my leadership skills to guide teams effectively.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal7"
                    value="Make a significant contribution to the global knowledge base."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Make a significant contribution to the global knowledge base."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal7">
                    Make a significant contribution to the global knowledge
                    base.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal8"
                    value="Drive organizational change and improve business outcomes."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Drive organizational change and improve business outcomes."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal8">
                    Drive organizational change and improve business outcomes.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal9"
                    value="Gain the skills and knowledge to mentor the next generation."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Gain the skills and knowledge to mentor the next generation."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal9">
                    Gain the skills and knowledge to mentor the next generation.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="goal10"
                    value="Achieve a well-rounded professional profile that balances expertise and social responsibility."
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes(
                      "Achieve a well-rounded professional profile that balances expertise and social responsibility."
                    )}
                  />
                  <label className="form-check-label" htmlFor="goal10">
                    Achieve a well-rounded professional profile that balances
                    expertise and social responsibility.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pursuement_goal"
                    id="customGoal"
                    value="custom"
                    onChange={handleGoalChange}
                    checked={selectedGoals.includes("custom")}
                  />
                  <label className="form-check-label" htmlFor="customGoal">
                    Enter your own goal:
                  </label>
                  {selectedGoals.includes("custom") && (
                    <input
                      type="text"
                      className="form-control mt-2"
                      name="customGoal"
                      value={customGoal}
                      onChange={handleCustomGoalChange}
                      placeholder="Your own long-term goal"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label htmlFor="university_qualities">
                  What criteria of this university are you most interested in?
                  (Select all that apply)
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria1"
                    value="Top Faculty, Research Opportunities, Global Network"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Top Faculty, Research Opportunities, Global Network"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria1">
                    Top Faculty, Research Opportunities, Global Network
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria2"
                    value="Strong Industry Connections, Internship Opportunities, Career Services"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Strong Industry Connections, Internship Opportunities, Career Services"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria2">
                    Strong Industry Connections, Internship Opportunities,
                    Career Services
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria3"
                    value="Diverse Student Community, International Exposure, Cultural Exchange Programs"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Diverse Student Community, International Exposure, Cultural Exchange Programs"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria3">
                    Diverse Student Community, International Exposure, Cultural
                    Exchange Programs
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria4"
                    value="State-of-the-Art Facilities, Cutting-Edge Technology, Modern Campus"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "State-of-the-Art Facilities, Cutting-Edge Technology, Modern Campus"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria4">
                    State-of-the-Art Facilities, Cutting-Edge Technology, Modern
                    Campus
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria5"
                    value="Focus on Entrepreneurship, Start-up Support, Innovation Hubs"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Focus on Entrepreneurship, Start-up Support, Innovation Hubs"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria5">
                    Focus on Entrepreneurship, Start-up Support, Innovation Hubs
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria6"
                    value="Comprehensive Curriculum, Flexibility in Course Selection, Interdisciplinary Studies"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Comprehensive Curriculum, Flexibility in Course Selection, Interdisciplinary Studies"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria6">
                    Comprehensive Curriculum, Flexibility in Course Selection,
                    Interdisciplinary Studies
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria7"
                    value="Global Rankings, Reputation, Accreditation"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Global Rankings, Reputation, Accreditation"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria7">
                    Global Rankings, Reputation, Accreditation
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria8"
                    value="Supportive Alumni Network, Mentorship Programs, Lifelong Learning Opportunities"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Supportive Alumni Network, Mentorship Programs, Lifelong Learning Opportunities"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria8">
                    Supportive Alumni Network, Mentorship Programs, Lifelong
                    Learning Opportunities
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria9"
                    value="Scholarships, Financial Aid, Tuition Assistance Programs"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Scholarships, Financial Aid, Tuition Assistance Programs"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria9">
                    Scholarships, Financial Aid, Tuition Assistance Programs
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="criteria10"
                    value="Community Engagement, Social Responsibility, Volunteer Opportunities"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes(
                      "Community Engagement, Social Responsibility, Volunteer Opportunities"
                    )}
                  />
                  <label className="form-check-label" htmlFor="criteria10">
                    Community Engagement, Social Responsibility, Volunteer
                    Opportunities
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="university_qualities"
                    id="customCriteria"
                    value="custom"
                    onChange={handleCriteriaChange}
                    checked={selectedCriteria.includes("custom")}
                  />
                  <label className="form-check-label" htmlFor="customCriteria">
                    Enter your own criteria:
                  </label>
                  {selectedCriteria.includes("custom") && (
                    <input
                      type="text"
                      className="form-control mt-2"
                      name="customCriteria"
                      value={customCriteria}
                      onChange={handleCustomCriteriaChange}
                      placeholder="Your own criteria"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
);

export default UniversityForm;
