import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './../img/perfect_sop_logo.png'; // Using the same logo as in the header

const Footer = () => {
    return (
        <footer className="footer" style={{
            backgroundColor: '#f8f9fa',
            padding: '20px 0',
            boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-4 text-center text-md-left mb-3 mb-md-0">
                        <img src={logo} alt="Perfect SOP Logo" className="footer-logo-img" style={{ height: '40px' }} />
                    </div>
                    <div className="col-md-4 text-center mb-3 mb-md-0">
                        <div className="footer-links">
                            <a href="/terms-of-service" target="_blank" style={{ color: '#007bff', textDecoration: 'none', margin: '0 10px' }}>
                                Terms of Service
                            </a>
                            <a href="/privacy-policy" target="_blank" style={{ color: '#007bff', textDecoration: 'none', margin: '0 10px' }}>
                                Privacy Policy
                            </a>
                            <a href="/refund-policy" target="_blank" style={{ color: '#007bff', textDecoration: 'none', margin: '0 10px' }}>
                                Refund Policy
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 text-center text-md-right">
                        <p style={{ margin: '0', fontSize: '14px', color: '#6c757d' }}>
                            &copy; 2024 Perfect SOP. All rights reserved.
                        </p>
                        <p style={{ margin: '0', fontSize: '14px', color: '#6c757d' }}>
                            <a href="mailto:contact@perfectsop.com" style={{ color: '#007bff', textDecoration: 'none' }}>
                                contact@perfectsop.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
