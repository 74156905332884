import React, { useEffect, useState } from "react";
import FeedbackModal from './FeedbackModel';

const PaymentSuccess = ({ sopParts, downloadPDF, startNewSOP }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(true);

  useEffect(() => {
    // Trigger PDF download if not already done
    downloadPDF();

    // Check if the user came through a Google AdWords campaign
    const urlParams = new URLSearchParams(window.location.search);
    const gclid = urlParams.get('gclid');

    if (gclid) {
      // Google Analytics event snippet for conversion tracking
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11237007452/f77rCK3Cxs0ZENzAnO4p',
        'value': 1.0,
        'currency': 'INR'
      });
    }
  }, [downloadPDF]);

  const wordCount = Object.values(sopParts)
    .map((part) => part?.text || "")
    .join(" ")
    .split(/\s+/).length;

  const handleFeedbackSubmit = async ({ rating, feedback }) => {
    try {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensure the cookie is included
        body: JSON.stringify({ rating, feedback }),
      });

      if (response.ok) {
        alert('Thank you for your feedback!');
        setShowFeedbackModal(false);
      } else {
        alert('Failed to submit feedback. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="alert alert-success text-center">
        Please see the full preview below, and you can download the PDF if it was not automatically downloaded.
      </div>

      {/* Move the button to the top-right corner */}
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-primary"
          onClick={downloadPDF}
        >
          Download PDF Again
        </button>
      </div>

      <div
        className="sop-preview p-4 border rounded bg-light document"
        style={{
          height: "80vh",
          overflowY: "auto",
          textAlign: "justify",
        }}
      >
        <h4 className="text-center mb-4">Statement of Purpose</h4>
        <div
          className="alert alert-info text-right"
          style={{
            paddingBottom: "5px",
          }}
        >
          Word Count: {wordCount}
        </div>
        <div>
          {sopParts.university && <p>{sopParts.university.text}</p>}
          {sopParts.academics && <p>{sopParts.academics.text}</p>}
          {sopParts.experience && <p>{sopParts.experience.text}</p>}
          {sopParts.extra_curricular && <p>{sopParts.extra_curricular.text}</p>}
          {sopParts.conclusion && <p>{sopParts.conclusion.text}</p>}
        </div>
      </div>

      {/* Feedback Modal */}
      <FeedbackModal
        show={showFeedbackModal}
        onSubmitFeedback={handleFeedbackSubmit}
      />
    </div>
  );
};

export default PaymentSuccess;
